export const GoalsData = [
  {
    id: 1,
    icon: "/images/network.svg",
    title: "Social Security",
    list: [
      {
        id: 1,
        content:
          "15 million migrants and other informal workers have access to stronger state delivery systems for social security schemes",
      },
      {
        id: 2,
        content:
          "25+ large companies are enabling access to social security entitlements for their workers",
      },
    ],
    impact: [
      {
        text: "Households registered with MRC",
        counter: 6.8,
        decimals: 1,
        suffix: "&nbsp;million +",
      },
      {
        text: "Social security benefits facilitated",
        counter: 7,
        // decimals: 1,
        suffix: "&nbsp;million +",
      },
      // {
      //   text: "Social security benefits facilitated in partnership with industry",
      //   counter: 25,
      //   decimals: 0,
      //   suffix: "k&nbsp;+",
      // },
    ],
  },
  {
    id: 2,
    icon: "/images/protection.svg",
    title: "Worker Protection",
    list: [
      // {
      //   id: 1,
      //   content:
      //     "Establish a national migrant worker helpline and dispatch support system as a national, government-adopted protection system.",
      // },
      {
        id: 2,
        content:
          "10 million workers and their families have access to helplines and dispatch support-related state remedies.",
      },
      // {
      //   id: 3,
      //   content: "Reduce forced labour",
      // },
    ],
    impact: [
      {
        text: "Calls received on the helpline",
        counter: 231,
        decimals: 0,
        suffix: "k&nbsp;+",
      },
      // {
      //   text: "Forced labour rescued",
      //   counter: 1500,
      //   decimals: 0,
      //   suffix: "&nbsp;+",
      // },
      {
        text: "Compensation recovered for workers",
        counter: 2.7,
        decimals: 1,
        suffix: "&nbsp;million +",
        prefix: "USD&nbsp;",
      },
    ],
  },
  // {
  //   id: 3,
  //   icon: "/images/leader.svg",
  //   title: "Responsible Recruitment",
  //   list: [
  //     {
  //       id: 1,
  //       content:
  //         "1 million workers are responsibly recruited i.e., are working under safe and fair employment conditions",
  //     },
  //   ],
  // },
];
